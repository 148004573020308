import { configureStore } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { setupClient } from "src/api/api.ts";
import { AuthState, UserState, authReducer } from "src/store/auth.ts";
import { Dialog, dialogReducer, hideDialog, showDialog } from "src/store/dialog.ts";
import { ToastMessage, addToast, showToast, toastReducer } from "src/store/toast.ts";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    toast: toastReducer,
    dialog: dialogReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useUserState = (): UserState => {
  const { user } = useAppSelector((state) => state.auth);
  if (user === null || user === undefined) {
    throw new Error("User is not set");
  }
  return user;
};

export const useAuthState = (): AuthState => {
  const { auth } = useAppSelector((state) => state.auth);
  if (auth === null || auth === undefined) {
    throw new Error("Auth is not set");
  }
  return auth;
};

interface ToastHook {
  toastMessages: ToastMessage[];
  showToast: (toast: string) => void;
}

export const useToasts = (): ToastHook => {
  const { toastMessages } = useAppSelector((state) => state.toast);
  const dispatch = useAppDispatch();

  const addToastMessage = useCallback(
    (toast: string) => {
      const id = uuidv4();
      dispatch(addToast({ message: toast, id: id }));
      setTimeout(() => dispatch(showToast(id)), 0);
    },
    [dispatch]
  );

  return {
    toastMessages: toastMessages,
    showToast: addToastMessage,
  };
};

interface DialogHook {
  dialog: Dialog | null;
  showDialog: (title: string, message: string) => void;
  hideDialog: () => void;
}

export const useDialog = (): DialogHook => {
  const { dialog } = useAppSelector((state) => state.dialog);
  const dispatch = useAppDispatch();

  return {
    dialog: dialog,
    showDialog: (title: string, message: string) => dispatch(showDialog({ title: title, message: message })),
    hideDialog: () => dispatch(hideDialog()),
  };
};

setupClient(store.getState);
