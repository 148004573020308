import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { isEqual } from "lodash";

import { Auth, fetchOwnUserDetails, refreshToken, updatePreferences } from "src/api/user.ts";
import { UserPreferences, UserWithPreferences } from "src/model/user.ts";
import { RootState, useAppDispatch } from "src/store/store.ts";
import {
  loadContainerColumnOrderSetting,
  loadContainerColumnSetting,
  loadContainerPageSize,
  loadSubscriptionColumnOrderSetting,
  loadSubscriptionColumnSetting,
  loadSubscriptionPageSize,
} from "src/utils/localStorage.ts";

export type UserRole = "Tracking_Nothing" | "Tracking_Admin" | "Tracking_Service";

export const UserRights = ["ViewSubscriptionReport", "ViewContainerReport"] as const;

export type UserRight = (typeof UserRights)[number];

interface AccessToken {
  Name: string;
  Role: UserRole;
  UserId: string;
  CustomerId?: string;
  CompanyId?: string;
  exp: number;
}

export interface UserState {
  username: string;
  role: UserRole;
  rights: UserRight[];
  customerId: string | null;
  companyId: string | null;
  preferences: UserPreferences | null;
}

export interface AuthState {
  accessToken: string;
  expiry: number;
  refreshToken: string;
  userId: number;
}

interface AuthReducerState {
  auth: AuthState | null | undefined;
  user: UserState | null | undefined;
}

const initialState: AuthReducerState = {
  auth: undefined,
  user: undefined,
};

export const parseAccessToken = (accessTokenString: string): AccessToken => {
  const accessTokenParts = accessTokenString.split(".");
  if (accessTokenParts[1] !== undefined) {
    return JSON.parse(atob(accessTokenParts[1])) as AccessToken;
  }
  throw new Error("Unable to parse access token of user");
};

const mapAuth = (user: Auth): AuthState => {
  const accessToken = parseAccessToken(user.accessToken);

  return {
    accessToken: user.accessToken,
    refreshToken: user.refreshToken,
    userId: parseInt(accessToken.UserId),
    expiry: accessToken.exp,
  };
};

const mapUserToState = (
  user: Pick<UserWithPreferences, "userRole" | "userRights" | "userName" | "customerId" | "companyId" | "preferences">
): UserState => {
  return {
    role: user.userRole,
    rights: user.userRights,
    username: user.userName,
    customerId: user.customerId,
    companyId: user.companyId,
    preferences: user.preferences,
  };
};

const isTokenExpired = (expiry: number): boolean => {
  return expiry * 1000 < Date.now() - 60000;
};

export const refreshAuth = async (dispatch: ReturnType<typeof useAppDispatch>, getState: () => RootState) => {
  const state = getState();
  const authState = state.auth.auth;
  if (authState === null || authState === undefined) {
    return;
  }
  if (isTokenExpired(authState.expiry)) {
    try {
      const auth = await refreshToken(authState.refreshToken);
      dispatch(setAuth(auth));
      const details = await fetchOwnUserDetails();
      dispatch(setUser(details));
    } catch (error) {
      console.error("Unable to refresh accessToken", error);
    }
  }
};

const migrateSubscriptionListPreferences = async (user: UserWithPreferences) => {
  const subscriptionColumnSetting = loadSubscriptionColumnSetting();
  const subscriptionColumnOrderSetting = loadSubscriptionColumnOrderSetting();
  const subscriptionPageSize = loadSubscriptionPageSize();

  const containerColumnSetting = loadContainerColumnSetting();
  const containerColumnOrderSetting = loadContainerColumnOrderSetting();
  const containerPageSize = loadContainerPageSize();

  if (
    user.preferences === null &&
    (subscriptionColumnSetting !== null ||
      subscriptionColumnOrderSetting !== null ||
      subscriptionPageSize !== null ||
      containerColumnSetting !== null ||
      containerColumnOrderSetting !== null ||
      containerPageSize !== null)
  ) {
    const preferences: UserPreferences = {
      subscriptionColumnSetting: subscriptionColumnSetting ?? undefined,
      subscriptionColumnOrderSetting: subscriptionColumnOrderSetting ?? undefined,
      subscriptionPageSize: subscriptionPageSize ?? undefined,

      containerColumnSetting: containerColumnSetting ?? undefined,
      containerColumnOrderSetting: containerColumnOrderSetting ?? undefined,
      containerPageSize: containerPageSize ?? undefined,
    };
    await updatePreferences(preferences);
    user.preferences = preferences;
  }

  localStorage.removeItem("containerColumnSetting");
  localStorage.removeItem("containerColumnOrderSetting");
  localStorage.removeItem("containerPageSize");
  localStorage.removeItem("subscriptionColumnSetting");
  localStorage.removeItem("subscriptionColumnOrderSetting");
  localStorage.removeItem("subscriptionPageSize");
};

const migrateAuthState = async (dispatch: ReturnType<typeof useAppDispatch>, outdatedAuthString: string) => {
  localStorage.removeItem("authentication");
  const outdatedAuthState = JSON.parse(outdatedAuthString) as Auth;
  try {
    const auth = await refreshToken(outdatedAuthState.refreshToken);
    dispatch(setAuth(auth));
    const user = await fetchOwnUserDetails();
    await migrateSubscriptionListPreferences(user);
    dispatch(setUser(user));
  } catch (error) {
    console.error(error);
    dispatch(clearUser());
  }
};

export const initialize = async (dispatch: ReturnType<typeof useAppDispatch>) => {
  const outdatedAuthString = localStorage.getItem("authentication");
  if (outdatedAuthString !== null) {
    await migrateAuthState(dispatch, outdatedAuthString);
    return;
  }

  const authString = localStorage.getItem("auth");

  if (authString === null) {
    dispatch(clearUser());
    return;
  }

  const authState = JSON.parse(authString) as AuthReducerState;
  if (authState.auth === null || authState.auth === undefined) {
    dispatch(clearUser());
    return;
  }

  if (isTokenExpired(authState.auth.expiry)) {
    try {
      const auth = await refreshToken(authState.auth.refreshToken);
      dispatch(setAuth(auth));
    } catch (error) {
      console.error(error);
      dispatch(clearUser());
      return;
    }
  }
  dispatch(setAuth(authState.auth));

  const user = await fetchOwnUserDetails();
  await migrateSubscriptionListPreferences(user);
  dispatch(setUser(user));
};

export const updateUserPreferences = (preferences: UserPreferences) => {
  return (dispatch: ReturnType<typeof useAppDispatch>, getState: () => RootState) => {
    const state = getState();
    const userState = state.auth.user;
    if (userState !== undefined && userState !== null) {
      const newPreferences: UserPreferences = {
        ...userState.preferences,
        ...preferences,
      };
      if (!isEqual(userState.preferences, newPreferences)) {
        updatePreferences(newPreferences).catch((error) => console.error(error));
        dispatch(setUserPreferences(newPreferences));
      }
    }
  };
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<Auth>) => {
      const newState = { ...state, auth: mapAuth(action.payload) };
      localStorage.setItem("auth", JSON.stringify(newState));

      return newState;
    },
    setUser: (
      state,
      action: PayloadAction<
        Pick<UserWithPreferences, "userRole" | "userRights" | "userName" | "customerId" | "companyId" | "preferences">
      >
    ) => {
      const newState = { ...state, user: mapUserToState(action.payload) };
      localStorage.setItem("auth", JSON.stringify(newState));

      return newState;
    },
    setUserPreferences: (state, action: PayloadAction<UserPreferences>) => {
      if (state.user !== null && state.user !== undefined) {
        const newUser: UserState = {
          ...state.user,
          preferences: action.payload,
        };
        return { ...state, user: newUser };
      }
      return state;
    },
    clearUser: () => {
      localStorage.removeItem("auth");
      return { user: null, auth: null };
    },
  },
});

export const authReducer = authSlice.reducer;
export const setUser = authSlice.actions.setUser;
export const setUserPreferences = authSlice.actions.setUserPreferences;
export const setAuth = authSlice.actions.setAuth;
export const clearUser = authSlice.actions.clearUser;
