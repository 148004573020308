import { FC, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";

import { FilteredSubscriptionLogList } from "src/components/subscriptionLogList/filteredSubscriptionLogList.tsx";
import { buildSubscriptionLogFilterParam } from "src/components/subscriptionLogList/subscriptionLogFilterUtils.ts";
import { Provider } from "src/components/subscriptionLogList/subscriptionLogListPaginationContext.ts";
import { PageRequest } from "src/model/pageRequest.ts";
import { SubscriptionLogFilterProps, SubscriptionLogPagination } from "src/model/subscriptionLog.ts";
import { parseSearchParamToNumber } from "src/utils/searchParams.ts";

const defaultPagination: PageRequest = {
  limit: 10,
  offset: 0,
};

interface FilterAndPagination {
  pagination: SubscriptionLogPagination;
  filter: SubscriptionLogFilterProps;
}

const parsePaginationFromUrl = (
  searchParams: URLSearchParams
): { pagination: SubscriptionLogPagination; filter: SubscriptionLogFilterProps } => {
  const offset = parseSearchParamToNumber(searchParams.get("offset"));
  const query = searchParams.get("query") ?? undefined;

  return {
    pagination: {
      ...defaultPagination,
      offset: offset ?? defaultPagination.offset,
    },
    filter: {
      ...(query !== undefined ? { query: query } : {}),
    },
  };
};

const updateSearchParams = (
  prev: URLSearchParams,
  filter: SubscriptionLogFilterProps,
  pagination: SubscriptionLogPagination
) => {
  Object.entries(pagination).forEach(([key, value]) => {
    if (value !== undefined) {
      if (prev.get(key) !== value) {
        prev.set(key, value);
      }
    } else {
      prev.delete(key);
    }
  });
  if (Object.keys(filter).length === 0) {
    prev.delete("filter");
  } else {
    const value = buildSubscriptionLogFilterParam(filter);
    if (prev.get("filter") !== value) {
      prev.set("filter", value);
    }
  }
};

export const SubscriptionLogListPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [{ filter, pagination }, setProps] = useState<FilterAndPagination>(() => parsePaginationFromUrl(searchParams));

  useEffect(() => {
    setProps(parsePaginationFromUrl(searchParams));
  }, [searchParams]);

  const setPagination = useMemo(
    () => (p: FilterAndPagination | ((state: FilterAndPagination) => FilterAndPagination)) => {
      setSearchParams((prev) => {
        const result = typeof p === "function" ? p(parsePaginationFromUrl(prev)) : { ...p };
        updateSearchParams(prev, result.filter, result.pagination);
        return prev;
      });
    },
    [setSearchParams]
  );

  useEffect(() => {
    setSearchParams(
      (prev) => {
        if (prev.size === 0) {
          const { pagination, filter } = parsePaginationFromUrl(prev);
          updateSearchParams(prev, filter, pagination);
        }
        return prev;
      },
      { replace: true }
    );
  }, [setSearchParams]);

  return (
    <>
      <Helmet>
        <title>iSEA Trace - Subscription Logs</title>
      </Helmet>
      <Provider
        value={{
          pagination: pagination,
          filter: filter,
          setPagination: setPagination,
        }}
      >
        <FilteredSubscriptionLogList />
      </Provider>
    </>
  );
};
