import { FC } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { createUser, updateUser } from "src/api/user.ts";
import { CompanyDropdown } from "src/components/companyDropdown.tsx";
import { CustomerDropdown } from "src/components/customerDropdown.tsx";
import { User } from "src/model/user.ts";
import { UserSaveRequest } from "src/model/userSaveRequest.ts";
import { UserRight, UserRights, UserRole } from "src/store/auth.ts";
import { nullIfEmpty } from "src/utils/strings.ts";

interface UserFormProps {
  user: User | null;
}

export interface UserFormData {
  active: boolean;
  userName: string;
  mail: string;
  userRole: UserRole;
  userRights: UserRight[];
  customerId?: string;
  companyId?: string;
}

const buildFormDefaultValues = (user: User | null): Partial<UserFormData> => {
  if (user === null) {
    return {
      active: true,
      userRole: "Tracking_Nothing",
    };
  }
  return {
    active: user.active,
    userName: user.userName,
    mail: user.mail,
    userRole: user.userRole,
    userRights: user.userRights,
    customerId: user.customerId ?? undefined,
    companyId: user.companyId ?? undefined,
  };
};

export const UserForm: FC<UserFormProps> = ({ user }) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<UserFormData>({
    defaultValues: buildFormDefaultValues(user),
  });
  const navigate = useNavigate();

  const onSubmit = async (userFormData: UserFormData) => {
    try {
      const userSaveRequest: UserSaveRequest = {
        active: userFormData.active,
        userName: userFormData.userName,
        mail: userFormData.mail,
        userRole: userFormData.userRole,
        userRights: userFormData.userRights,
        customerId: nullIfEmpty(userFormData.customerId),
        companyId: nullIfEmpty(userFormData.companyId),
      };
      if (user === null) {
        await createUser(userSaveRequest);
      } else {
        await updateUser(user.id, userSaveRequest);
      }
      navigate("/users");
    } catch (error) {
      console.error(error);
    }
  };

  const userRole = watch("userRole");
  register("customerId", {
    required: userRole === "Tracking_Nothing" ? "This field is required." : false,
    disabled: userRole === "Tracking_Admin",
  });
  register("companyId", { disabled: userRole === "Tracking_Admin" });

  const onChangeCustomerId = (customerId: string | undefined) => {
    setValue("customerId", customerId);
    setValue("companyId", undefined);
  };

  const onChangeCompanyId = (companyId: string | undefined) => {
    setValue("companyId", companyId);
  };

  return (
    <Row>
      <Col xs={12} sm={10} md={9} xl={6} className="m-auto mt-5">
        <div className="bg-white shadow p-3">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column="sm" sm={3}>
                Username
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  {...register("userName", { required: "This field is required." })}
                  isInvalid={errors.userName?.message !== undefined}
                />
                <Form.Control.Feedback type="invalid" data-testid="validationFeedback">
                  {errors.userName?.message}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column="sm" sm={3}>
                Mail
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  placeholder="Mail"
                  {...register("mail", { required: "This field is required." })}
                  isInvalid={errors.mail?.message !== undefined}
                />
                <Form.Control.Feedback type="invalid" data-testid="validationFeedback">
                  {errors.mail?.message}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column="sm" sm={3}>
                Active
              </Form.Label>
              <Col sm={9} className="d-flex align-items-center">
                <Form.Switch {...register("active")} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column="sm" sm={3}>
                Role
              </Form.Label>
              <Col sm={9}>
                <Form.Select {...register("userRole")}>
                  <option value="Tracking_Nothing">User</option>
                  <option value="Tracking_Admin">Admin</option>
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column="sm" sm={3}>
                Rights
              </Form.Label>
              <Col sm={9}>
                {UserRights.map((role, index) => (
                  <div key={index}>
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        id={`role-${role}`}
                        {...register("userRights")}
                        value={role}
                        disabled={userRole === "Tracking_Admin"}
                      />
                      <Form.Check.Label htmlFor={`role-${role}`}>{role}</Form.Check.Label>
                    </Form.Check>
                  </div>
                ))}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column="sm" sm={3}>
                Customer
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as={CustomerDropdown}
                  customerId={watch("customerId")}
                  disabled={userRole === "Tracking_Admin"}
                  allowDeselect={true}
                  onChangeCustomerId={onChangeCustomerId}
                  isInvalid={errors.customerId?.message !== undefined}
                />
                <Form.Control.Feedback type="invalid" data-testid="validationFeedback">
                  {errors.customerId?.message}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column="sm" sm={3}>
                Company
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as={CompanyDropdown}
                  disabled={userRole === "Tracking_Admin"}
                  customerId={watch("customerId")}
                  companyId={watch("companyId")}
                  onChangeCompanyId={onChangeCompanyId}
                />
              </Col>
            </Form.Group>

            <div className="d-flex justify-content-between">
              <Link to="/users" className="btn btn-outline-primary">
                Cancel
              </Link>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};
