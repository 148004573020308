import { debounce } from "lodash";
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";

import { fetchContainerReportRequest, fetchContainers } from "src/api/containers.ts";
import { ColumnDropdown } from "src/components/columnDropdown/columnDropdown.tsx";
import { ContainerList, ContainerRow } from "src/components/containerList/containerList.tsx";
import { containerColumns } from "src/components/containerList/containerListColumns.tsx";
import { usePaginationContext } from "src/components/containerList/containerListPaginationContext.ts";
import { Pagination } from "src/components/pagination.tsx";
import { config } from "src/config.ts";
import { PaginatedResponse } from "src/model/paginatedResponse.ts";
import { Container } from "src/model/subscription.ts";
import { updateUserPreferences } from "src/store/auth.ts";
import { useAppDispatch, useUserState } from "src/store/store.ts";

export const FilteredContainerList: FC = () => {
  const user = useUserState();
  const [columns, setColumns] = useState<(keyof ContainerRow)[]>(
    user.preferences?.containerColumnSetting ?? containerColumns.map((c) => c.key)
  );
  const [columnOrder, setColumnOrder] = useState<(keyof ContainerRow)[]>(
    user.preferences?.containerColumnOrderSetting ?? containerColumns.map((c) => c.key)
  );
  const [containers, setContainers] = useState<PaginatedResponse<Container> | undefined>();
  const { pagination, filter, setPagination } = usePaginationContext();
  const [search, setSearch] = useState(filter.query ?? "");
  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchContainers(pagination, filter)
      .then((response) => {
        setContainers(response);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, [pagination, filter]);

  const onPageChange = (offset: number) => {
    setPagination((prevState) => ({ ...prevState, pagination: { ...prevState.pagination, offset: offset } }));
  };

  const onPageSizeChange = (limit: number) => {
    setPagination((prevState) => ({ ...prevState, pagination: { ...prevState.pagination, limit: limit } }));
  };

  useEffect(() => {
    if (columns !== null) {
      dispatch(updateUserPreferences({ containerColumnSetting: columns }));
    }
  }, [dispatch, columns]);

  useEffect(() => {
    if (columnOrder !== null) {
      dispatch(updateUserPreferences({ containerColumnOrderSetting: columnOrder }));
    }
  }, [dispatch, columnOrder]);

  const onSearch = useCallback(
    (search: string) => {
      const query = search.length > 0 ? search : undefined;
      setPagination((prevState) => ({ ...prevState, filter: { ...prevState.filter, query: query } }));
    },
    [setPagination]
  );

  const debouncedSearch = useMemo(() => {
    return debounce(onSearch, 500);
  }, [onSearch]);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);

    debouncedSearch(value);
  };

  const onReportDownload = async () => {
    // showDialog("Download Subscription Report", "Body")
    const containerReportRequest = await fetchContainerReportRequest(filter, {
      sortBy: pagination.sortBy,
      sortDir: pagination.sortDir,
    });

    window.open(`${config.apiUrl}/API/iSEA5/V2.1/Containers/Reports/${containerReportRequest.downloadToken}`, "_blank");
  };

  if (containers === undefined || columns === null || columnOrder === null) {
    return null;
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <Col xs={3}>
          <InputGroup>
            <Form.Control value={search} onChange={onSearchChange} placeholder="Search" />
            <Button onClick={() => onSearch(search)} variant="outline-primary">
              <Search />
            </Button>
          </InputGroup>
        </Col>

        <div className="flex-grow-1" />

        {(user.role === "Tracking_Admin" || user.rights.includes("ViewSubscriptionReport")) && (
          <Button onClick={onReportDownload} className="me-3">
            Download As Excel
          </Button>
        )}

        <ColumnDropdown
          items={containerColumns}
          selectedColumns={columns}
          columnOrder={columnOrder}
          setColumnOrder={(order: (keyof ContainerRow)[]) => setColumnOrder(order)}
          setSelectedColumns={(columns: (keyof ContainerRow)[]) => setColumns(columns)}
        />
      </div>
      <ContainerList containers={containers.items} columns={columnOrder.filter((c) => columns.indexOf(c) >= 0)} />
      <Pagination
        totalItems={containers.totalItems}
        offset={pagination.offset}
        limit={pagination.limit}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </>
  );
};
