import { FC, useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useSearchParams } from "react-router-dom";

import { HttpError } from "src/api/httpError.ts";
import { fetchOwnUserDetails, refreshToken } from "src/api/user.ts";
import { setUser } from "src/store/auth.ts";
import { useAppDispatch } from "src/store/store.ts";

export const LoginWithTokenPage: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string>();

  const loginWithToken = useCallback(
    async (token: string, backurl: string | null) => {
      try {
        const loginResponse = await refreshToken(decodeURIComponent(token));
        const details = await fetchOwnUserDetails();
        dispatch(setUser({ ...loginResponse, ...details }));
        if (backurl !== null) {
          navigate(decodeURIComponent(backurl), { replace: true });
        } else {
          navigate("/", { replace: true });
        }
      } catch (error) {
        if (error instanceof HttpError) {
          if (error.statusCode === 400) {
            setError(error.message);
          } else if (error.statusCode === 403) {
            setError("Unable to login with provided token.");
          }
        } else {
          console.error(error);
        }
      }
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    const token = searchParams.get("token");
    if (token !== null) {
      loginWithToken(token, searchParams.get("backurl"));
    }
  }, [searchParams, loginWithToken]);

  return (
    <>
      <Helmet>
        <title>iSEA Trace - Login</title>
      </Helmet>
      <div>{error}</div>
    </>
  );
};
